import React from "react";
import { DeepstopIcon,SafetyStopIcon ,FastUpIcon,DecoIcon,OxygenToxicityIcon,CriticalDepthIcon} from "../assets";


const Section5 = () => {
  const data = [
    {
      icon: DeepstopIcon,
      tag: "Deep Stop",
    },
    {
      icon: SafetyStopIcon,
      tag: "Safety Stop",
    },
    {
      icon: FastUpIcon,
      tag: "Fast-Up",
    },
    {
      icon: DecoIcon,
      tag: "Deco",
    },
    {
      icon: OxygenToxicityIcon,
      tag: "Oxygen Toxicity",
    },
    {
      icon: CriticalDepthIcon,
      tag: "Critical Depth",
    },
  ];
  return (
    <section className="xl:mt-24 md:mt-96 mt-12 w-full">
      <h1 className="JosefinSansBold text-[#0F1D46] text-[50px] leading-[60px] text-center">
        Safety <span className="text-[#26A8E2]">Alerts</span>
      </h1>
      <div className="xl:px-12 px-10">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5 justify-items-center">
          {data.map((item, index) => (
            <div key={index} className="flex flex-col items-center mt-12 gap-5">
              <img src={item.icon} alt="" width={140} height={140} />
              <p className="text-[#0F1D46] MontserratBold text-[24px] text-center w-[80px]">
                {item.tag}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section5;
