import React from "react";
import { bannerMob, bannerTab, iconA, iconC, iconB, iconD } from "../assets";
import { useIsMobile } from "../hooks/useIsMobile";


const Section15 = () => {
  const isMobile = useIsMobile(650);


  const data = [
    {
      icon: iconA,
      tag: "Advanced Dive Metrics",
      desc: "Track crucial real-time data such as depth, time, and temperature for safer dives, providing better insights.",
    },
    {
      icon: iconB,
      tag: "Seamless Data Sync",
      desc: "Easily synchronize dive data with the DIVEROID app for comprehensive analysis and sharing with fellow enthusiasts.",
    },
    {
      icon: iconC,
      tag: "Durable Construction",
      desc: "Built to withstand the rigors of underwater exploration, ensuring reliability in any dive condition.",
    },
    {
      icon: iconD,
      tag: "Aesthetic  Design",
      desc: "Combines functionality with sleek aesthetics, making it a versatile accessory both in and out of the water.   ",
    },
  ];
  return (
    <section className="mt-24 w-full relative ">
      <img
        src={isMobile ? bannerMob : bannerTab}
        alt=""
        // className={`w-full ${isMobile ? 'h-[2560px]' : isDefaultMobile ?'h-[2100px] md:h-[1500px] ms:h-[1000px]  xl:h-full' :'h-[3000px] md:h-[1500px] ms:h-[1000px]  xl:h-full'}`}
        className={`w-full ${isMobile ?'h-[2560px]' :'sm:h-[2100px] md:h-[2200px] lg:h-[1500px] xl:h-full'}`}
      />

      <div className="absolute inset-10 ">
        <h1 className="JosefinSansBold text-[50px] leading-[60px] text-[#0F1D46] text-center">
          Why
          <span className="block text-white ml-0">DIVEROID?</span>
        </h1>

        <div className="w-full place-items-center grid md:grid-cols-2 grid-cols-1 xl:grid-cols-4 gap-3 mt-14  px-[80px]">
          {data.map((item, index) => (
            <div key={item?.icon} className="flex flex-col gap-[30px] w-full items-center">
              <img src={item.icon} alt="" width={120} height={120} />
              <h1 className="text-center MontserratBold text-[22px] px-10 text-white">
                {item.tag}
              </h1>
              <p className="text-center text-[16px] MontserratRegular text-white">
                {item.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section15;
