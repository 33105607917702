import React from 'react';
import { icon1, icon3, icon2 } from '../assets';

const Features = () => {
    const cards = [
        {
            icon: icon1,
            heading: "Dive Computers",
            paragraph: "Our dive computers deliver real-time metrics for enhanced safety and convenience, ensuring you dive confidently with up-to-date information."
        },
        {
            icon: icon2,
            heading: "Cost Effective",
            paragraph: "Our dive computers offer essential features at an affordable price, perfect for budget-conscious divers seeking top quality."
        },
        {
            icon: icon3,
            heading: "Logbook App Synced",
            paragraph: "Sync your dive logbook and photos directly through your watch, capturing and organizing every underwater adventure automatically."
        }
    ];

    return (
        <section id="benifits" className='flex justify-center items-center xl:px-[100px]  mt-24'>
        <div className='flex flex-wrap justify-center  gap-8  '>
            {cards.map((card, index) => (
                <div key={index} className='max-w-[367px] xl:h-auto flex flex-col items-center gap-[20px] '>
                    <img src={card.icon} alt="" />
                    <h1 className='text-[24px] leading-[28.8px] MontserratBold text-[#05084D] text-center'>{card.heading}</h1>
                    <p className='MontserratRegular text-[16px] leading-[30px] text-center text-[#05084D] '>{card.paragraph}</p>
                </div>
            ))}
        </div>
    </section>
    
    );
};

export default Features;
