import ReactGA from 'react-ga4';

export const analyticEvent = (category, action) => {
    ReactGA.event({
        category,
        action,
    });   

}

export const referralEvent = (referralCode) => {

    analyticEvent('Referral', `Visited with Referral Code ${referralCode}`)
}

export const referralSubmissionEvent = (referralCode) => {

    analyticEvent('Referral Submission', `Submission with Referral Code ${referralCode}`)
}

export const directSubmissionEvent = () => {

    analyticEvent('Direct Submission', `Direct Submission`)
}