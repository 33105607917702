import React from "react";
import { footerBanner, DiveroidIconWhite, fb, youtube, linkedin, twitter, skype, FooterBannerMobile } from "../assets";
import { useIsMobile } from "../hooks/useIsMobile";

const Footer = () => {
  const isMobile = useIsMobile(800);


  return (
    <section className="xl:mt-24 w-full relative " >
      <img src={isMobile?FooterBannerMobile:footerBanner} alt="" className="sm:h-full md:h-full w-full relative" />
      <div className="absolute inset-y-[20%] md:inset-y-[30%] xl:inset-y-1/2 px-5 md:px-10  xl:px-[150px] md:flex gap-20" style={{
        left: isMobile ? "5%" : "0%",
      }}>
        <div className="flex flex-col gap-[30px] w-[287px] ">
          <img src={DiveroidIconWhite} alt="" height={30} width={190} />
          <p className="text-white text-[16px] leading-[30px] MontserratRegular">
            Discover the world's most versatile and affordable diving computer
            solution, providing accurate data and real-time monitoring for a
            groundbreaking underwater experience.
          </p>
        </div>
        <div className="flex flex-col gap-[30px] mt-4  ">
          <h1 className="text-white JosefinSansBold text-[22px]">
            Quick Links
          </h1>
          <ul className="text-white text-[16px] leading-[30px] MontserratRegular">
          <a href="#home">
              {" "}
              <li className="cursor-pointer MontserratRegular text-[16px] hover:underline underline-offset-8  decoration-[#27A6E2]  ">
                Home
              </li>
            </a>
            <a href="#benifits">
              {" "}
              <li className="cursor-pointer MontserratRegular text-[16px]  hover:underline underline-offset-8  decoration-[#27A6E2] ">
                Benefits
              </li>
            </a>
            <a href="#Features">
              {" "}
              <li className="cursor-pointer MontserratRegular text-[16px]  hover:underline underline-offset-8  decoration-[#27A6E2]  ">
                Feature
              </li>
            </a>
            <a href="#reviews">
              {" "}
              <li className="cursor-pointer MontserratRegular text-[16px] hover:underline underline-offset-8  decoration-[#27A6E2]  ">
                Review
              </li>
            </a>
            <a href="#pricing">
              {" "}
              <li className="cursor-pointer MontserratRegular text-[16px]  hover:underline underline-offset-8  decoration-[#27A6E2]">
                Pricing
              </li>
            </a>
            <a href="#contact">
              {" "}
              <li className="cursor-pointer MontserratRegular text-[16px]   hover:underline underline-offset-8  decoration-[#27A6E2]  ">
                Contact
              </li>
            </a>
          </ul>
        </div>
        <div className="flex flex-col gap-[30px] w-[185px] mt-4 ">
          <h1 className="text-white JosefinSansBold text-[22px]">Social</h1>
          <p className="text-white text-[16px] leading-[30px] MontserratRegular">
            Data and real-time monitoring for a groundbreaking underwater
            experience.
          </p>

          <div className="flex gap-[10px]">
            <a href="https://www.facebook.com/artisannocean/" target="_blank">       <img src={fb} alt=""  className="cursor-pointer"/></a>
     {/* <a href="https://www.instagram.com/diveroid_global/"></a> */}
     <a href="https://www.youtube.com/@diveroid7675" target="_blank"> <img src={youtube} alt=""  className="cursor-pointer"/></a>
      <a href="https://twitter.com/diveroid/" target="_blank"><img src={twitter} alt=""  className="cursor-pointer"/></a>
        <a href="https://www.linkedin.com/company/diveroid/" target="_blank">   <img src={linkedin} alt="" className="cursor-pointer" /></a>
           
         

          </div>
        </div>

      </div>
      <p className="absolute bottom-2 md:bottom-0 xl:bottom-10 text-white left-5 md:left-10 xl:left-20">© 2024 Diveroid pvt ltd. All Rights Reserved</p>



    </section>
  );
};

export default Footer;
