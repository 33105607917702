import React, { useEffect, useState } from "react";
import { bronze, Gold, Silver, ribbon } from "../assets";

const Section17 = () => {

  const [loading,setLoading] = useState(false);
  const BASE_DATA =[
    {
      icon: Gold,
      name: "",
      points: 0,
    },
    {
      icon: Silver,
      name: "",
      points: 0,
    },
    {
      icon: bronze,
      name: "",
      points: 0,
    },
  ];
  const [data, setData] = useState(BASE_DATA);

  const userRank = async (e) => {

      setLoading(true)
      try {
    

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/rank`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to submit form");
       
        }

        const responseData = await response.json();

        const newData = BASE_DATA.map((item,index) => {
          if (responseData?.data[index]) {
            return {
                ...item,
                name: responseData?.data[index]?.name,
                totalpoints: responseData?.data[index]?.totalpoints,
              }
          } else {
              return item
          }
        
        })

        setData(newData)
    
        setLoading(false)
      } catch (error) {
        console.error("Error submitting form:", error.message);
         setLoading(false)
      }
    
  };

  useEffect(()=> {
    userRank();
  },[])

  return (
    <section className="mt-24 max-w-full md:px-[120px] px-10">
      <h1 className="JosefinSansBold text-[50px] leading-[60px] text-[#0F1D46] text-center">
        Dive Into Success <br />
        <span className="text-[#00ACE5]">Top Referral Champions</span>
      </h1>
      <p className=" text-[16px] leading-[30px] text-center MontserratRegular">
        Welcome to{" "}
        <span className="text-[#00ACE5] MontserratBold">
          Our Dive Watch Referral Program
        </span>{" "}
        is not just about earning; it's about leading, inspiring, and showcasing
        the prowess of our top ambassadors. Meet the champions, the connoisseurs
        of the deep blue, who have mastered the art of referral and are setting
        the bar higher for dive watch enthusiasts around the globe.
      </p>
      <div className="grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-full items-center justify-between mt-6">
        {!loading && data.map((item, index) => (
          <div
            key={index}
            className={`flex items-center gap-[12px] flex-wrap justify-center ${
              data.length === 3 && index === 2
                ? "md:col-span-2 lg:col-span-1"
                : ""
            }`}
          >
            <div className="flex items-center justify-center">
              <img className="mr-[-20px]" src={ribbon} alt="" />
              <img src={item?.icon} alt="" />
            </div>

            <div className="flex flex-col gap-[16px] items-center">
              <h1 className="MontserratBold text-[18px] leading-[21px] text-[#00ACE5] text-center">
                {item?.name}
              </h1>
              <p className="text-[#898A8D] MontserratRegular text-[18px] text-center">
                <span className="MontserratBold text-[16px] leading-[21px] text-[#00ACE5]">
                  {item?.totalpoints || 0}
                </span>{" "}
                Points
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Section17;
