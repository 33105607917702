import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css";
import HeroSection from "./components/HeroSection";
import Section1 from "./components/Features";
import Section2 from "./components/Section2";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section7 from "./components/Section7";
import Section8 from "./components/Section8";
import Section9 from "./components/Section9";
import Section10 from "./components/Section10";
import Section11 from "./components/Section11";
import Section12 from "./components/Section12";
import Section14 from "./components/Section14";
import Section15 from "./components/Section15";
import Section16 from "./components/Section16";
import Section17 from "./components/Section17";
import Footer from "./components/Footer";
import ReactGA from 'react-ga4';

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
})

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />} />
        <Route path="/:referralCode" element={<MainLayout />} />
      </Routes>
    </Router>
  );
};

const MainLayout = () => {
  return (
    <>
      <HeroSection />
      <Section1 />
      <Section2 />
      <Section4 />
      <Section5 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
      <Section12 />
      <Section14 />
      <Section15 />
      <Section16 />
      <Section17 />
      <Footer />
    </>
  );
};

export default App;
