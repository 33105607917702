import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { format } from 'date-fns';

const ChildElement = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* 캘린더 스타일링 */
  .react-datepicker {
    background-color: white; /* 캘린더 배경색 */
    border: 1px solid #ccc; /* 캘린더 테두리 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
  }  

  .react-datepicker__month-container {
    padding: 0 1rem;
  }

  .react-datepicker__header {
    background-color: white; /* 헤더 배경색 */
    border-bottom: 1px solid #00ace5; /* 헤더 테두리 */
  }

  .react-datepicker__day-name, .react-datepicker__day {
    color: #333; /* 날짜 색상 */
  }

  .react-datepicker__day:hover {
    background-color: #00ace5; /* 호버 시 배경색 변경 */
    cursor: pointer; /* 호버 시 포인터 모양 */
  }

  /* 선택된 날짜의 스타일링 */
  .react-datepicker__day--selected {
    background-color: #00ace5; /* 선택된 날짜의 배경색 */
    color: white; /* 선택된 날짜의 텍스트 색상 */
  }

  /* 연도 드롭다운 스타일링 */
  .react-datepicker__year-dropdown {
    background-color: white; /* 드롭다운 배경색 */
    border: 1px solid #ccc; /* 드롭다운 테두리 */
    max-height: 150px; /* 드롭다운 최대 높이 */
    overflow-y: auto; /* 스크롤 활성화 */
  }

  .react-datepicker__year-option {
    padding: 0.5rem; /* 연도 옵션 패딩 */
  }

  .react-datepicker__year-option:hover {
    background-color: #00ace5; /* 호버 시 배경색 변경 */
    color: white; /* 호버 시 텍스트 색상 */
  }

  .react-datepicker__year-option--selected {
    background-color: #00ace5; /* 선택된 연도 배경색 */
    color: white; /* 선택된 연도 텍스트 색상 */
  }
`;

const CustomDatePicker = ({ selectedDate, onChange }) => {
  const minDate = new Date(1940, 0, 1); // 1940년 1월 1일
  const maxDate = new Date(); // 현재 날짜

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      onChange(formattedDate); // 포맷된 날짜 문자열을 상위 컴포넌트에 전달
    } else {
      onChange(null); // 날짜가 선택되지 않은 경우
    }
  };

  return (
    <ChildElement className="w-full">
      <DatePicker
        placeholderText="Date of Birth"
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
        showYearDropdown // 연도 선택 드롭다운 활성화
        scrollableYearDropdown // 스크롤 가능한 연도 목록 활성화
        minDate={minDate} // 선택 가능한 최소 날짜
        maxDate={maxDate} // 선택 가능한 최대 날짜
        yearDropdownItemNumber={85} // 드롭다운에 표시할 연도의 수
        className="h-[50px] w-[80%] md:w-full border px-4 rounded-md placeholder:text-[#757575] placeholder:MontserratRegular placeholder:text-[16px] outline-none"
        // Tailwind CSS classes for styling
      />
    </ChildElement>
  );
};

export default CustomDatePicker;
