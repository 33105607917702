import React, { useState } from 'react';
import { Listbox } from '@headlessui/react';

const CustomSelect = ({ formData, setFormData, formErrors }) => {
  const [selectedGender, setSelectedGender] = useState(formData.gender || '');

  const genders = [
    { value: '', label: 'Select Gender' },
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];

  const handleChange = (gender) => {
    setSelectedGender(gender);
    setFormData({ ...formData, gender });
  };

  return (
    <Listbox value={selectedGender} onChange={handleChange} style={{width:"100%", display:'flex', alignItems:'center', justifyContent:'center'}}>
      <div className="relative">
        <Listbox.Button className={`h-[50px] w-[80%] md:w-full border px-4 rounded-md text-left ${
            formErrors.gender ? "border-red-500" : "border-gray-[#e5e7eb]"
          } ${!selectedGender ? 'text-[#757575]' : 'text-black'}`}>
          {genders.find(g => g.value === selectedGender)?.label}
        </Listbox.Button>
        <Listbox.Options className="absolute w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg z-10">
          {genders.map((gender) => (
            <Listbox.Option key={gender.value} value={gender.value} className="cursor-pointer select-none relative py-2 pl-10 pr-4 hover:bg-blue-100">
              {gender.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default CustomSelect;
